import React from "react";

const AspireApp = () => {

    return (
        <div>
            <p className="font-bold text-2xl text-black">You don't have access to this page.</p>
        </div>
    );
};

export default AspireApp;
